import { getTokenFromCookie } from '@/utils/cookies';
import { Router, scrollBehavior } from '@/utils/router.js';
import { setHtmlMeta, hostBuild } from '@/utils/utils';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "core_car" */ './home/home.vue'),
      meta: { desc: '首页' },
    },
    {
      // params.brand 品牌
      path: '/list/used/:brand?',
      name: 'ListUsed',
      component: () => import(/* webpackChunkName: "core_car" */ './list-used/list-used.vue'),
      meta: { desc: '二手车列表页' },
    },
    {
      // params.brand 品牌
      path: '/list/lease/:brand?',
      name: 'ListLease',
      component: () => import(/* webpackChunkName: "core_car" */ './list-lease/list-lease.vue'),
      meta: { desc: '转Lease列表页' },
    },
    {
      path: '/detail/:topic/:id',
      name: 'Detail',
      component: () => import(/* webpackChunkName: "core_car" */ './detail/detail.vue'),
      meta: { desc: '详情页' },
    },
    {
      path: '/publish/:topic',
      name: 'Publish',
      component: () => import(/* webpackChunkName: "core_car" */ './publish/publish.vue'),
      meta: { desc: '发布页', isAuth: true },
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import(/* webpackChunkName: "core_car" */ './search/search.vue'),
      meta: { desc: '搜索页' },
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_car" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_car" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // 这里只能用本地token进行判断是否登录，否则刷新页面会出现问题
  const token = getTokenFromCookie();
  if (to.meta.isAuth === true && !token) {
    location.replace(hostBuild(`/login?redirect=${location.href}`, 'account'));
    return next(false);
  }

  if (['Detail', 'Publish'].includes(to.name) && !['used', 'lease'].includes(to.params.topic)) {
    return next({ name: 'NotFound' });
  }

  if (to.name == 'Home') {
    setHtmlMeta({
      title: '多伦多汽车 - 约克论坛 - 加拿大第一中文网',
      keywords: '多伦多汽车,买车,卖车,汽车新闻,汽车保养,汽车行情,汽车评测,汽车经纪,经销商,二手车,新能源车',
      description:
        '多伦多华人和留学生免费发布和查询汽车信息平台，汇集汽车论坛、Markham、Richmond Hill、North York、Scarborough、Etobicoke、Mississauga等地宝马、奔驰等二手车及转lease车源和华人车行及汽车销售。',
    });
  } else if (to.name == 'ListUsed') {
    setHtmlMeta({
      title: '多伦多二手车 - 多伦多汽车 - 约克论坛',
      keywords: '多伦多二手车,买车,卖车,代卖,汽车经纪,汽车销售,0首付,0dp,补贴,car,auto,used car,二手车转让,求购二手车,',
      description:
        '多伦多二手车交易平台，华人车主直卖，BMW,Mercedes-Benz,Volkswagen,Audi,Porsche,Subaru等车型，Markham、Richmond Hill等地实地看车。yorkbbs.ca',
    });
  } else if (to.name == 'ListLease') {
    setHtmlMeta({
      title: '多伦多转lease - 多伦多汽车 - 约克论坛',
      keywords: '多伦多二手车,转lease,转车,求lease,还车保险,0首付,0dp,补贴,汽车经纪,汽车销售,car,auto,used car,回国转lease',
      description:
        '多伦多转LEASE交易平台，转LEASE、接LEASE、新车转让，免费发布，免费对接车主，包括BMW、Mercedes-Benz、Volkswagen、Audi、Porsche, Subaru等车型，Markham、Richmond Hill等地实地看车。yorkbbs.ca',
    });
  } else if (to.name == 'Publish') {
    setHtmlMeta({
      title: `${to.params.topic == 'used' ? '二手车' : '转lease'} - 发布信息 - 多伦多汽车 - 约克论坛`,
      keywords: '',
      description: '',
    });
  } else if (to.name == 'Search') {
    setHtmlMeta({
      title: '搜索 - 多伦多汽车 -  约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'WebSafe') {
    setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'NotFound') {
    setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
  } else {
    setHtmlMeta({ title: '', keywords: '', description: '' });
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });

  next();
});

export { router };
