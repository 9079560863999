<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <YorkbbsLoginDialog :type.sync="loginType" :visible.sync="loginVisible" />
    <YorkbbsMobileAlert></YorkbbsMobileAlert>
  </div>
</template>
<script>
import { getUserMixin } from '@/utils/user.mixin';
import { ExternalLink } from '@/utils/ExternalLink';
import YorkbbsLoginDialog from '@/common/account/login/dialog-login.vue';
import YorkbbsMobileAlert from '@/common/alert-mobile.vue';
export default {
  components: { YorkbbsMobileAlert, YorkbbsLoginDialog },
  mixins: [getUserMixin],
  computed: {
    loginType: {
      get() {
        return this.$store.getters.loginDialogStatus.type;
      },
      set(type) {
        this.$store.commit('setLoginType', type);
      },
    },
    loginVisible: {
      get() {
        return this.$store.getters.loginDialogStatus.visible;
      },
      set(status) {
        this.$store.commit('setLoginDialogVisible', status);
      },
    },
  },
  mounted() {
    const externalLink = new ExternalLink();
    externalLink.addListener();
    this.$once('hook:beforeDestroy', () => {
      externalLink.removeListener();
    });
  },
};
</script>
